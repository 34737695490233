.Search {
    margin: 0 !important;
    border: none !important;
}

.form-control {
    height: 40px;
    line-height: 40px;
}

.ant-btn:hover {
    background-color: transparent;
}

.ant-btn:focus {
    background-color: transparent;
}

#Cashier-Search-puma {
    color: white;
}

.New-header-content-search{
    height: 2.4rem;
    align-items: center;
    display: flex;
    margin-top: 5px;
}

.custom-search-container {
    display: flex;
    align-items: center;
    width: 12rem!important;
    flex-direction: row;
}

.custom-search-input {
    background: none;

}

.custom-search-clear {
    padding: 0 0.5rem;
}

.header-search-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
}

.New-header-search-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (min-device-width: 1000px){
    .New-header-content-search{
        position: absolute;
        right: 5rem;
        width: 16rem;
        top: 0.5rem;
    }
    .New-header-search-container{
        padding-right: 3rem;
        justify-content: flex-end;
    }
}
